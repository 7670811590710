import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import {
	SolicitationCreationModalComponent,
	SolicitationSearchFormModalComponent,
} from "@e-tenant-hub/client-platform/shared/features/modals";
import { NotificationsService } from "@e-tenant-hub/client-platform/shared/ui/responsive/notifications";
import { SolicitationsFilterService, SolicitationsService } from "@e-tenant-hub/shared/rentals";
import { ResponsiveModalContainerService } from "@e-tenant-hub/shared/ui/responsive/modal-container";
import { ScreenSizeService } from "@e-tenant-hub/shared/utils";
import { Observable, map } from "rxjs";

@Component({
	selector: "cp-header-search-toolbar",
	templateUrl: "./header-search-toolbar.component.html",
	styleUrl: "./header-search-toolbar.component.scss",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderSearchToolbarComponent {
	readonly solicitationsService = inject(SolicitationsService);
	private readonly modalContainerService = inject(ResponsiveModalContainerService);
	private readonly notificationsService = inject(NotificationsService);
	private readonly screenSizeService = inject(ScreenSizeService);
	private readonly solicitationsFilterService = inject(SolicitationsFilterService);

	get hasFilters$(): Observable<boolean> {
		return this.solicitationsFilterService.onFilterChanged$.pipe(map((v) => v.length > 0));
	}

	get isSmartphone$(): Observable<boolean> {
		return this.screenSizeService.observeSmallScreen();
	}

	openSolicitationsFiltersModal(): void {
		this.modalContainerService.openDialog(SolicitationSearchFormModalComponent);
	}

	openSolicitationCreationModal(isNewSolicitationRequestDisabled: boolean) {
		if (isNewSolicitationRequestDisabled) {
			this.notificationsService.showInformationMessage(
				"Le nombre de création de demande par jour est limité à 5 et a été atteint."
			);
			return;
		}

		this.modalContainerService.openDialog(SolicitationCreationModalComponent);
	}
}
