import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EthIconComponent } from "@e-tenant-hub/shared/ui/icon";
import { FooterComponent } from "./footer.component";

@NgModule({
	imports: [CommonModule, EthIconComponent],
	declarations: [FooterComponent],
	exports: [FooterComponent],
})
export class FooterModule {}
